import React, { useEffect, useMemo, useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';

import themeLight from './_theme/theme-light';
import themeDark from './_theme/theme-dark';

import { ThemeProvider } from '@mui/system';
import { CssBaseline, useMediaQuery } from '@mui/material';
import { Toast } from './_components/toast';
import { PageLoader, InitialLoader } from './_components/loader';
import { getBrowserRouter } from './routes';

import { AppNameContext } from './_contexts';
import { APP_NAME } from './_helpers';
import { GlobalAction } from './redux/actions';

function App() {

    const dispatch = useDispatch();
    const { pageLoader, initialLoader, toastMessage: toast, theme } = useSelector((state) => state.GlobalReducer);
    const [isDarkMode, setIsDarkMode] = useState(false);

    useEffect(() => {
        dispatch(GlobalAction.initialLoader(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: light)');
    useMemo(() => setIsDarkMode(theme === 'dark' ? true : ((theme === 'light') ? (false) : prefersDarkMode)), [prefersDarkMode, theme]);


    return (
        <AppNameContext.Provider value={APP_NAME}>
            <HelmetProvider>
                <ThemeProvider theme={isDarkMode ? themeDark : themeLight}>
                    <CssBaseline />
                    <RouterProvider router={createBrowserRouter(getBrowserRouter())} />
                    <PageLoader loader={pageLoader} />
                    <InitialLoader loader={initialLoader} />
                    <Toast message={toast} />
                </ThemeProvider>
            </HelmetProvider>
        </AppNameContext.Provider>
    );
}

export { App };
