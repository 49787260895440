const CryptoJS = require('crypto-js');

const encryptionKey = "JDGIuFdi74COS0AOwyh1+x7dDRrWckEjTZFoReH3Sgg=";




module.exports = {

    decryptData: async (encryptedData) => {


        const encryptionKeyBytes = CryptoJS.enc.Base64.parse(encryptionKey); // Convert Base64 key to bytes
        const encryptedBytes = CryptoJS.enc.Base64.parse(encryptedData); // Convert Base64 encrypted data to bytes

        const iv = CryptoJS.lib.WordArray.create(encryptedBytes.words.slice(0, 4)); // Extract first 16 bytes (IV)
        const ciphertext = CryptoJS.lib.WordArray.create(encryptedBytes.words.slice(4)); // Remaining is the ciphertext

        const decrypted = CryptoJS.AES.decrypt({ ciphertext: ciphertext }, encryptionKeyBytes, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });

        return decrypted.toString(CryptoJS.enc.Utf8); // Convert decrypted data back to JSON

    },


    encryptData: async (plainText) => {
        const key = CryptoJS.enc.Base64.parse(encryptionKey);
        const iv = CryptoJS.lib.WordArray.random(16);

        const encrypted = CryptoJS.AES.encrypt(plainText, key, { iv: iv, mode: CryptoJS.mode.CBC });

        const encryptedData = CryptoJS.enc.Base64.stringify(iv.concat(encrypted.ciphertext));

        return encryptedData;
    }

};